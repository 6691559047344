import React, { Component } from 'react'
import Layout from '../layout'
import CaseStudiesSideMenu from '../case-studies/caseStudiesSideMenu'
import CaseStudiesCategories from '../case-studies/caseStudiesCategories'
//import { TranslatedLink } from '../TranslatedLink'
import { getTranslateFunction, scrollToSlug } from '../../utilities'
import CaseStudiesSideMenuGhost from '../case-studies/caseStudiesSideMenuGhost'
//import LinkBtn from '../linkBtn/LinkBtn'
import PageTitle from '../pageTitle'
import Reveal from '../reveal'
import ContaktUs from '../contactUs/ContactUs'
import './Pages.scss';



class applicationsTemplate extends Component {

  constructor(props) {
    super(props)
    this.onEnterViewport = this.onEnterViewport.bind(this)
    this.visibleElementChangeHandler = this.visibleElementChangeHandler.bind(this)
    this.state = {
      visibleElement: null,
    }
  }

  onEnterViewport(id) {
    this.setState({
      visibleElement: id,
    })
  }

  visibleElementChangeHandler(category) {
    this.setState({
      visibleElement: category,
    })
  }

  componentDidMount() {
    scrollToSlug()
    CaseStudiesSideMenuGhost.setCurrentPage('applications')
  }

  render() {
    const { data, lang } = this.props
    const description = data.references.childMarkdownRemark.frontmatter.description

    const t = getTranslateFunction(lang)

    const categories = [{
      case_studies: data.references.childMarkdownRemark.frontmatter.case_studies,
      categoryName: 'app',
      other_projects: data.references.childMarkdownRemark.frontmatter.other_projects,
    }]

    CaseStudiesSideMenuGhost.setActivePage(
      lang,
      'applications',
      [],
      false,
      this.state.visibleElement,
      this.visibleElementChangeHandler)

    return (
      <Layout lang={lang} className={'mobile-menu-offset footer-hide our-work'}>

        <section className="references project-refs references-groups margin-top">
          <div className="d-md-flex">
            <div className="d-none d-lg-block col-md-3" id="left">
              <CaseStudiesSideMenu
                categoriesList={[]}
                visibleElementChangeHandler={this.visibleElementChangeHandler}
                visibleElement={this.state.visibleElement}
                isGhost={false} />
            </div>
            <div className="col-lg-9 pr-lg-0">
              <div className="row subpage-title">
                <div className="col-md-10">
                  <PageTitle title={t('References.ApplicationsTitle')} description={description} />
                  <p>{description}</p>
                  {/*   <div className="col-auto">
                    <LinkBtn>
                      <TranslatedLink to="/en/contact">{t('References.ContactUs')}</TranslatedLink>
                    </LinkBtn>
                  </div> */}
                </div>
              </div>

              <CaseStudiesCategories scrollHandler={this.onEnterViewport} categories={categories} lang={lang} />

            </div>
          </div>
        </section>

        <Reveal>
                <ContaktUs
            LeftSideSubTitle={t('References.MoreProjects.Graphic')}
            LeftSideLink="/en/our-works/graphics"
            linkRight="/en/contact"
            rightSecondLink="/en/brief" />
        </Reveal>
      </Layout>
    )
  }
}

export default applicationsTemplate
