import React from 'react'
import ApplicationsTemplate from '../../components/pages/applicationsTemplate.js'
import { graphql } from 'gatsby'

function applicationsSk({ data, ...otherProps }) {
  return <ApplicationsTemplate data={data} lang={'sk'} {...otherProps}/>
}

export const query = graphql`{
    references: file(relativePath: {eq: "pages/sk/references/applications.md"}) {
        childMarkdownRemark {
            frontmatter {
                description
                case_studies {
                    title
                    description
                    thumbnail_layers {
                        thumbnail {
                            childImageSharp {
                                fluid( maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
background_color
                    category
                    case_study
                }
                other_projects {
            title
            url_override
            show_in_references_slider
            url
                    category
                    thumbnail {
                        childImageSharp {
                            fluid( maxWidth: 1000) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
}
`


export default applicationsSk
